$('.frontBoks').hover(function(event){
	var el = $(this).find('.desc'),
		curHeight = el.height(),
		autoHeight = el.css('height', 'auto').height() + 20;
	el.height(curHeight).stop(true).animate({height: autoHeight}, 500);
}, function(event) {
	$(this).find('.desc').stop(true).animate({height:55}, 500);
});

//Menu bar skrumber
$(window).scroll(function () {
	if ($(this).scrollTop() < 1) {
		$( ".navbar-fixed-top" ).removeClass('scroll');
	} else {
		$( ".navbar-fixed-top" ).addClass('scroll');
	}
});

$('.nextDivision .next1 .fa').click(function (){
	
	if($(this).hasClass('fa-arrow-down')){
		$('.nextDivision').animate({right: "-150"}, 500);
	}
	else {
		$('.nextDivision').animate({right: "0"}, 500);
	}
							 
	$(this).toggleClass('fa-arrow-down');
	$(this).toggleClass('fa-arrow-up');
});

$(document).ready(function () {
	$('a.lightbox').imageLightbox(
	{
		selector:       'class="imagelightbox"'
	});
});

$("#form").validate({
	rules: {
		//Faktura adresse
		signName: "required",
		signEmail: {required: true, email: true},
		signCount: {required: true, number: true, min: 1}
	}, 
	messages: {
		//Faktura adresse
		signName: "Indtast dit navn!",
		signEmail: "Indtast en korrekt email!",
		signCount: "Indtast antal af personer!"
	}
});